import React from 'react';
import './style.scss';

const TriangleIcon = (props) => {

    return (
        <svg id="Layer_1" className='triangle-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.78 223.58">
            <defs></defs>
            <polygon className="cls-1" points="143.78 0 71.9 223.58 0 0 143.78 0"/>
        </svg>

    )
}
export default TriangleIcon;