import React from 'react';
import './style.scss';
// import image from './i/placeholder-1.jpg';
import customer from 'assets/images/customer.png';
import banking from 'assets/images/banking.png';
import fraud from 'assets/images/fraud.png';
import underwriting from 'assets/images/underwriting.png';

const SolutionsInfoBlocks = (props) => {

    return (
        <div className='blockwrap solutions-info'>
            <div className='container'>
                <div className='content'>
                    <div className='content-block reverse row-1'>
                        <img src={banking} alt="Revolutionize your card issuing" className='padding-left'/>

                        <div className='info-wrap'>
                            <h2>Revolutionize your<br /> card issuing</h2>
                            <p>Have your transaction data enriched in under 50ms, and use exact merchant, category, and geolocation details to drive new features and services for your customers.</p>
                            <ul>
                                <li>Enhance real-time authorization capabilities</li>
                                <li>Modernize transaction feeds</li>
                                <li>Implement granular spend controls</li>
                            </ul>
                        </div>
                    </div>

                    <div className='content-block row-2'>
                        <img src={customer} alt="Elevate your customer experiences."  />

                        <div className='info-wrap'>
                            <h2>Elevate your<br /> customer<br className='mobile' /> experiences</h2>
                            <p>Get a holistic sense of where and how your customers are spending, anticipate their needs and wants, and offer a dynamic, precise picture of their financial activity.</p>
                            <ul>
                                <li>Improve customers’ financial outcomes</li>
                                <li>Tailor solutions to customers’ goals</li>
                                <li>Increase customer engagement and LTV</li>
                            </ul>
                        </div>
                    </div>

                    <div className='content-block reverse row-1'>
                        <img src={fraud} alt="Detect and prevent fraud" />

                        <div className='info-wrap'>
                            <h2>Detect and <br />prevent fraud</h2>
                            <p>Get a more accurate view of where, why, and how customers are spending, so you can stop fraud before a transaction is processed.</p>
                            <ul>
                                <li>Fine-tune fraud detection models</li>
                                <li>Immediately flag unauthorized spending</li>
                                <li>Enable geography-based fraud prevention</li>
                            </ul>
                        </div>
                    </div>

                    <div className='content-block row-2'>
                        <img src={underwriting} alt="Underwrite on stronger data" />

                      <div className='info-wrap'>
                        <h2>Power rewards programs</h2>
                        <p>Get direct visibility into where your customers use their cards down to the latitude and
                          longitude the moment it occurs.</p>
                        <ul>
                          <li>Increase payout accuracy with unified merchant identifiers</li>
                          <li>Reduce false negatives improving customer experiences</li>
                          <li>Build unique rewards programs built on merchant, geography, and category without relying
                            on inconsistent data
                          </li>
                        </ul>
                      </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default SolutionsInfoBlocks;
