import React from 'react';
import './style.scss';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';

const GetStartedBlock = (props) => {
    const [isToggled, toggle] = useToggle(false);

    return (
        <div className='blockwrap get-started'>
               <div className='container'>
                    <div className='content'>
                        <h3>Build with an API that knows<br className='desktop' />
                          <span style={{ fontFamily: "'MessinaSansMono', monospace" }}>
                            MGRSBUX 53846
                          </span>
                          &nbsp;isn’t where
                          <br className='desktop' /> your customer stopped<br className='desktop' /> for coffee.</h3>
                        <button onClick={toggle} className='btn-general'>Learn More</button>
                    </div>
               </div>
               {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
                    <FormApiAccess />
                </Modal>}
        </div>

    )
}
export default GetStartedBlock;