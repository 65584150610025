import gsap from "gsap";
import { Stage, Layer, Image, Group } from "react-konva";
import { useLayoutEffect, useState, useRef } from "react";
import useImage from "use-image";
import { useInView } from "react-intersection-observer";
import { useParentSize } from "@cutting/use-get-parent-size";

import Block from "./animations/BlockAnimation";
import DataBitBlocksGroup from "./animations/DataBitBlocksGroupAnimation";
import CardsAnimation from "./animations/CardsAnimation";
import DataLinesAnimation from "./animations/DataLinesAnimation";

// const RefSprite = () => {
//     const [image] = useImage("./animation-images/Hero/ref-hero.png");
//     return <Image image={image} />;
// };

const Beacon = () => {
    const [image] = useImage("/animation-images/Hero/green-beacon.png");
    return <Image image={image} />;
};

const PodiumImage = () => {
    const [image] = useImage("/animation-images/Hero/podium.png");
    return <Image image={image} />;
};

const CANVAS_WIDTH = 715;
const CANVAS_HEIGHT = 773;

const HeroAnimation = ({ maxHeight, parentRef, isMobile }) => {
    const [inViewRef, inView] = useInView({ threshold: 0.4 });
    const {width:parentWidth} = useParentSize(parentRef);
    const beaconRef = useRef();
    const fadeInGroupRef = useRef();

    const fadeInGrouptl = useRef();
    const beacontl = useRef();

    const [scale, setScale] = useState({ x: 1, y: 1 });
    const yShift = -10;

    useLayoutEffect(() => {
        let s = 1;
        if(isMobile) {
            s = parentWidth / CANVAS_WIDTH;
        } else {
            s = maxHeight / CANVAS_HEIGHT;
        }
        setScale({ x: s, y: s });

        let ctx = gsap.context(() => {
            beacontl.current = gsap
                .timeline({ repeat: -1 })
                .fromTo(
                    beaconRef.current,
                    { opacity: 0.1 },
                    { opacity: 1.0, duration: 2.0, ease: "sine.inOut" }
                )
                .fromTo(
                    beaconRef.current,
                    { opacity: 1.0 },
                    { opacity: 0.1, duration: 2.0, ease: "sine.inOut" }
                );

            fadeInGrouptl.current = gsap
                .timeline()
                .from(fadeInGroupRef.current, {
                    y: 70,
                    opacity: 0,
                    duration: 3,
                    ease: "sine.inOut",
                });

            if (inView) {
                beacontl.current.play();
                fadeInGrouptl.current.play();
            } else {
                beacontl.current.pause(0.1);
                fadeInGrouptl.current.pause(0.1);
            }
        });

        return () => ctx.revert();
    }, [inView, isMobile, parentWidth, maxHeight]);

    return (
        <div ref={inViewRef}>
            <Stage
                width={CANVAS_WIDTH * scale.x}
                height={maxHeight}
                scale={scale}
                listening={false}
            >
                <Layer>
                    {/* <RefSprite /> */}
                    <Group y={yShift}>
                        <Group y={260}>
                            <PodiumImage />
                        </Group>

                        <CardsAnimation inView={inView} />

                        <Group y={3} x={142} ref={beaconRef} opacity={0.1}>
                            <Beacon />
                        </Group>
                        <Group ref={fadeInGroupRef}>
                            <Group x={655} y={330}>
                                <Block inView={inView} />
                            </Group>
                            <Group
                                x={578}
                                y={128}
                                rotation={60}
                                scale={{ x: 0.8, y: 0.8 }}
                            >
                                <Block inView={inView} />
                            </Group>
                            <Group x={57} y={305} rotation={-65}>
                                <Block inView={inView} />
                            </Group>
                            <Group
                                x={100}
                                y={180}
                                rotation={-110}
                                scale={{ x: 0.7, y: 0.7 }}
                            >
                                <Block inView={inView} />
                            </Group>
                            <DataBitBlocksGroup
                                x={29}
                                y={205}
                                colors={["#b7b4f9"]}
                                inView={inView}
                            />
                            <DataBitBlocksGroup
                                x={20}
                                y={404}
                                colors={["#70de7d", "#e2d9da"]}
                                scales={[1.0, 0.8]}
                                inView={inView}
                            />
                            <DataBitBlocksGroup
                                x={663}
                                y={428}
                                colors={["#b7b4f9", "#e2d9da"]}
                                scales={[1.0, 1.0]}
                                swapPos
                                inView={inView}
                            />
                            <DataBitBlocksGroup
                                x={565}
                                y={275}
                                colors={["#70de7d"]}
                                scales={[1.2]}
                                inView={inView}
                            />
                            <DataBitBlocksGroup
                                x={595}
                                y={192}
                                colors={["#e2d9da"]}
                                scales={[0.7]}
                                inView={inView}
                            />
                            <DataBitBlocksGroup
                                x={625}
                                y={235}
                                colors={["#e2d9da"]}
                                scales={[0.5]}
                                inView={inView}
                            />
                        </Group>
                    </Group>
                </Layer>
                <Layer>
                    <Group y={yShift}>
                        <DataLinesAnimation inView={inView} />
                    </Group>
                </Layer>
            </Stage>
        </div>
    );
};

export default HeroAnimation;
