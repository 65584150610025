import React from 'react';
import './style.scss';

const NavDots = (props) => {
    const {isToggled} = props;
    return (
   
        <svg version="1.1" id="Layer_1" className={`nav-dots ${props.addClass} ${isToggled ? 'open' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.1 70.3"  xmlSpace="preserve">
            <circle className="cls-1" cx="10.2" cy="9.9" r="8.5"/>
            <circle className="cls-1" cx="10.2" cy="35.4" r="8.5"/>
            <circle className="cls-1" cx="10.2" cy="60.9" r="8.5"/>
        </svg>

    )
}
export default NavDots;
