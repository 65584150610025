import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';

const MobileMenu = (props) => {
    const [isToggled, toggle] = useToggle(false);

    return (
      <>
          {props.openMenu && <Helmet><html class="open-menu" /></Helmet>}

          <div className={`mobile-menu-wrap ${props.openMenu ? 'open' : ''}`}>
              <nav>
                  <ul>
                      <li>
                          <Link to="/about" className="main">About</Link>
                      </li>
                      <li>
                          <Link to="/solutions" className="main">Solutions</Link>
                      </li>
                      <li>
                          <a href="https://docs.spade.com" className='main'>Docs</a>
                      </li>
                      <li>
                          <a href="https://blog.spade.com" className='main'>Blog</a>
                      </li>
                      <li>
                          <button onClick={toggle} className="access">Learn More</button>
                      </li>
                      <li>
                          <a href="mailto:hello@spade.com" className="contact hello">hello@spade.com</a>
                      </li>
                      <li>
                          <a href="https://blog.spade.com" title="contact link" className='footer-link' target='_blank'  rel="noreferrer">Blog</a>
                      </li>
                      <li>
                          <a href="https://twitter.com/Spadedata">Twitter</a>
                      </li>
                      <li>
                          <a href="https://www.linkedin.com/company/spadedata/">Linkedin</a>
                      </li>
                      <li>
                          <Link to="/terms" className="menu-terms">Terms of Service</Link>
                      </li>
                      <li>
                          <Link to="/privacy">Privacy Policy</Link>
                      </li>
                      <li>
                          <Link to="/security">Security</Link>
                      </li>
                  </ul>
              </nav>
          </div>
          {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
              <FormApiAccess />
          </Modal>}

      </>
    )
}
export default MobileMenu;