import React from 'react';
import './style.scss';
import Wrapper from '../../../templates/Wrapper/Wrapper';
import {Helmet} from "react-helmet-async";

const MSA = (props) => {

    return (
        <Wrapper addClass="privacy-terms" {...props}>

            <Helmet>
                <title>Spade | MSA</title>
                <meta name="description" content="Learn more about Spade's Terms of Service"/>
            </Helmet>

            <div className="blockwrap hero">
                <div className="container">
                    <div className="content">
                      <object
                        data="/documents/Spade MSA and API License Agreement.pdf"
                        type="application/pdf"
                        width="100%"
                        height="1000px"
                      >
                        PDF document of Spade MSA
                      </object>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}
export default MSA;