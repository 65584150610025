import React from 'react';
import './style.scss';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

const FormApiAccess = (props) => {
  const maxUsernameLen = 50;
  const validationSchema = new Joi.object({
    FullName: Joi.string()

      .min(3)
      .max(maxUsernameLen)
      .required()
      .label('Full name.')
      .messages({
        "any.required": "{{#label}} is required!!",
        "string.empty": "Please enter your Full Name",
      }),
    Role: Joi.string()
      .min(2)
      .required()
      .messages({
        "any.required": "{{#label}} is required!!",
        "string.empty": "Please enter your Role",
      }),
    Email: Joi.string()
      .email({tlds: { allow: false }})
      .required()
      .messages({
        "any.required": "Email is required!!",
        "string.empty": "Please enter a valid Email",
      }),
    CompanyWebsite: Joi.string()
      .domain()
      .required()
      .messages({
        "any.required": "Company website is required!!",
        "string.empty": "Please enter a valid website",
      }),
    HeardAboutUs: Joi.string()
        .min(2)
        .required()
        .messages({
          "any.required": "{{#label}} is required!!",
          "string.empty": "Please enter how you heard about Spade",
        }),

  });

  const { register, handleSubmit, formState: {errors} } = useForm({
    mode: 'onBlur',
    resolver: joiResolver(validationSchema)
  });

  const submitForm = (data, e) => {
    e.preventDefault();
    e.target.submit();
    console.log('submitted ', e);
    console.log('data ', data);
  }

  // NOTE! READ BEFORE EDITING FORM //
  //
  // Because we are using a js rendered react, netlify cannot parse our html to link to the forms correctly
  // As a work around the form is also defined (but hidden) on index.html, if you edit the form make sure you also edit index.html
  // https://docs.netlify.com/forms/setup/#work-with-javascript-rendered-forms
  // https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
  // https://docs.netlify.com/forms/setup/
  //


  return (
    <div className='container form-api-access'>

      <div className='content'>

        <form className='' onSubmit={handleSubmit(submitForm)} method="POST" data-netlify="true"
              name="request_access_form" action="/">
          <input name="form-name" value="request_access_form" type="hidden"/>
          <div className='input-wrap'>
            <input {...register("FullName", {required: "Please enter your full name."})} placeholder='Full name*'/>
            {errors.FullName && <p className='error-msg'>{errors.FullName.message}</p>}
          </div>

          <div className='input-wrap'>
            <input {...register("Role")} placeholder='Role*'/>
            {errors.Role && <p className='error-msg'>{errors.Role.message}</p>}
          </div>

          <div className='input-wrap'>
            <input {...register("Email")} placeholder='Email*'/>
            {errors.Email && <p className='error-msg'>{errors.Email.message}</p>}
          </div>

          <div className='input-wrap'>
            <input {...register("CompanyWebsite")} placeholder='Company website*'/>
            {errors.CompanyWebsite && <p className='error-msg'>{errors.CompanyWebsite.message}</p>}
          </div>

          <div className='input-wrap'>
            <input {...register("HeardAboutUs")} placeholder='How did you hear about us?*'/>
            {errors.HeardAboutUs && <p className='error-msg'>{errors.HeardAboutUs.message}</p>}
          </div>

          <div className='input-wrap'>
            <textarea name='description' cols='40' rows='20' placeholder='Description'/>
          </div>

          <button className='submit btn-general' type='submit'>Learn More</button>
        </form>
      </div>

    </div>

  )
}
export default FormApiAccess;