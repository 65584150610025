import { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { Group, Line, Path } from "react-konva";

const GradientLine = ({ x, y, side }) => {
    const gradientLineRef = useRef();

    const points =
        side === "right"
            ? [0, 0, 230, -130, 230, -80, 0, 50]
            : [0, 0, -230, -130, -230, -80, 0, 50];
    const gradientStart = { x: 0, y: 25 };
    const gradientEnd =
        side === "right" ? { x: 230, y: -40 } : { x: -230, y: -40 };
    const gradientStops = [0, "#a09df1", 0.5, "#b3aff1", 1.0, "#a09df1"];

    return (
        <Line
            ref={gradientLineRef}
            x={x}
            y={y}
            points={points}
            fillLinearGradientStartPoint={gradientStart}
            fillLinearGradientEndPoint={gradientEnd}
            fillLinearGradientColorStops={gradientStops}
            closed
            // opacity={0.5}
        />
    );
};

const DataLine = ({ inView, x, y, side, cacheUpdate }) => {
    const tl = useRef();
    const lineRef = useRef();
    const [lineDashes, setLineDashes] = useState([]);

    const points = side === "right" ? "M0,0 L215,-122z" : "M0,0 L-205,-122z";

    // useEffect(() => {
    //     console.log(lineDashes)
    // },[lineDashes])

    useLayoutEffect(() => {
        const lineLength = lineRef.current.getLength();
        const gapLength = 40;

        const numOfDashes = Math.floor(Math.random() * 6 + 4);
        const numOfSpaces = numOfDashes + (numOfDashes % 2 === 0) ? 1 : 0;
        const dashLength = (lineLength - numOfSpaces * gapLength) / numOfDashes;
        // console.log("dashLength: ", dashLength);
        // console.log("spots: ", numOfDashes+numOfSpaces)
        const dashes = Array.apply(null, new Array(2)).map((_, i) => {
            if (i % 2) return gapLength;
            return dashLength;
        });

        setLineDashes(dashes);

        let ctx = gsap.context(() => {
            if (cacheUpdate) {
                tl.current = gsap.timeline({
                    repeat: -1,
                    yoyo:true,
                    onUpdate: () => {
                        cacheUpdate.cache();
                    },
                });
            } else {
                tl.current = gsap.timeline({ repeat: -1 });
            }
            tl.current.to(lineRef.current, {
                dashOffset: lineLength,
                duration: Math.random() * 0.5 + 2,
                ease: "none",
            });
        });

        if (inView) {
            tl.current.play();
        } else {
            tl.current.pause(0.1);
        }

        return () => ctx.revert();
    }, [inView, cacheUpdate]);

    return (
        <Path
            ref={lineRef}
            x={x}
            y={y}
            data={points}
            // dash={[25, 10, 170, 10]}
            dash={lineDashes}
            stroke={"white"}
            strokeWidth={5}
            lineCap={"round"}
            lineJoin={"round"}
        />
    );
};

const DataLinesAnimation = ({ inView }) => {
    const fadeIntl = useRef();
    const fadeRef = useRef();
    const cacheRef = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            fadeIntl.current = gsap
                .timeline()
                .from(fadeRef.current, { opacity: 0, duration: 3 });
        });

        if (inView) {
            fadeIntl.current.play();
        } else {
            fadeIntl.current.pause(0.1);
        }
        return () => ctx.revert();
    }, [inView]);

    return (
        <Group ref={fadeRef} y={260}>
            <Group>
                <GradientLine x={345} y={355} side="right" inView={inView} />
                <GradientLine x={332} y={350} side="left" inView={inView} />
            </Group>
            <Group globalCompositeOperation="destination-in" ref={cacheRef}>
                <DataLine
                    x={352}
                    y={362}
                    side="right"
                    inView={inView}
                    cacheUpdate={cacheRef.current}
                />
                <DataLine x={352} y={377} side="right" inView={inView} />
                <DataLine x={352} y={392} side="right" inView={inView} />
                <DataLine x={323} y={362} side="left" inView={inView} />
                <DataLine x={323} y={377} side="left" inView={inView} />
                <DataLine x={323} y={392} side="left" inView={inView} />
            </Group>
        </Group>
    );
};

export default DataLinesAnimation;
