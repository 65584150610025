import React from 'react';
import './style.scss';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';

const Header = (props) => {
   
    let { isMobile } = props;
    let header = isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />
    return (
        header
    )
}
export default Header;