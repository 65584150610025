import React from 'react';
import './style.scss';
// import image from './i/placeholder.jpg';
import overview from 'assets/images/overview.png';
import { useRef, lazy, Suspense } from 'react';

const OverviewAnimation = lazy(() => import('components/OverviewAnimation/OverviewAnimation'));

const HowSpadeWorks = (props) => {

    const containerRef = useRef();
    // const {isMobile} = props;

    return (
        <div className="blockwrap how-spade-works">
              <div className="container" >
                    <div className="content" ref={containerRef} >
                        <h2>How Spade works</h2>

                            <Suspense fallback={<img src={overview} alt="overview" />}>
                                <OverviewAnimation parentRef={containerRef} />
                            </Suspense>

                        <div className="info-wrap">
                            <ul>
                                <li>
                                    <span>1.</span><p>You send Spade the transaction data you’ve generated or received from an outside source.</p>
                                </li>
                                <li>
                                    <span>2.</span><p>Spade’s API takes that data and maps it to a real merchant identity, assigning correct spending categories, geolocation details, and many additional, proprietary fields.</p>
                                </li>
                                <li>
                                    <span>3.</span><p>Spade returns cleaner, richer, more actionable data to your platform in a matter of milliseconds.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
              </div>
        </div>

    )
}
export default HowSpadeWorks;