import React, {lazy, Suspense, useRef} from 'react';
import './style.scss';

const TransactionAnimation = lazy(() => import("components/TransactionAnimation/TransactionAnimation"));
const SolutionAnimation = lazy(() => import("components/SolutionAnimation/SolutionAnimation"));

const TransactionData = ({isMobile}) => {

    const blockTransactionParentRef = useRef();
    const blockSolutionParentRef = useRef();

    return (
        <div className='blockwrap about-content'>
            <div className='container'>
                <div className='content' style={{ marginTop: "80px" }}>
                    <div className='content-block' style={{ borderTop: "none" }}>

                        <div className='block-1'>
                            <h2>Access to transaction data has never been better, but quality has never been worse.</h2>
                            <p>Fintechs rely on transaction data to learn about their customers’ finances and build fresh solutions that meet their particular needs.</p>
                            <p>The problem is, data sets can vary wildly from source to source, and they come in cryptic strings of letters, numbers, and symbols that yield little insight into where, how, or why a payment was made.</p>
                            <p>Companies end up wasting countless hours and resources trying to decipher these transaction details through guesswork and Googling—or worse, paying a third party to do the same. Ultimately, poor data quality gets in the way of innovation.</p>
                        </div>

                        <div className='block-2' ref={blockTransactionParentRef}>
                            <Suspense>
                                <TransactionAnimation maxHeight={480} parentRef={blockTransactionParentRef} isMobile={isMobile} />
                            </Suspense>
                        </div>

                    </div>

                    <div className='content-block row-2'>

                        <div className='block-1'>
                            <h2>The solution is Spade.</h2>
                            <p>Spade is the only transaction enrichment API backed by real data. Our ground truth network brings instant clarity and context to your transaction data, with accurately matched merchant, category, and geolocation details tapped straight from the source.</p>
                            <p>That means no guesswork, no Googling—and no building on bad or incomplete data.</p>
                        </div>

                        <div className='block-2' ref={blockSolutionParentRef}>
                            <Suspense>
                                <SolutionAnimation maxHeight={480} parentRef={blockSolutionParentRef} isMobile={isMobile} />
                            </Suspense>
                        </div>

                    </div>
                    
                </div>
            </div>
             
        </div>

    )
}
export default TransactionData;