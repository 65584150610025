import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import image from './i/DeveloperFocused.png';
import image1 from './i/PlatformAgnostic@2x.png';
import image2 from './i/QuickIntegration@2x.png';

const DeveloperFocused = (props) => {

    return (
        <div className="blockwrap developer-focused">
             <div className="container">
                <div className="content">

                    <div className="side a">
                        <h2>Developer-<br />focused</h2>
                        <p>Spade’s API works out of the box with<br /> no complex setup or configuration.</p>

                        <div className="item-wrap">
                            <div className="item">
                                <img src={image1} />
                                <p>Issuer / processor <br />agnostic</p>
                            </div>
                            <div className="item">
                                <img src={image2} />
                                <p>Integrate in <br /> under an hour</p>
                            </div>
                        </div>

                        {/* <Link to="/docs" className='btn-general'>Read The Docs</Link> */}
                        <a href="https://docs.spade.com/" className='btn-general' target="_blank" rel="noopener">Read The Docs</a>
                    </div>

                    <div className='side b'>
                        <img src={image} />
                    </div>
                  
                </div>
             </div>
        </div>

    )
}
export default DeveloperFocused;