import React from 'react';
import './style.scss';
import Wrapper from '../../../templates/Wrapper/Wrapper';
import {Helmet} from "react-helmet-async";

const Security = (props) => {

    return (
        <Wrapper addClass="security" {...props}>

            <Helmet>
                <title>Spade | Security Program</title>
                <meta name="description" content="Learn about Spade's Security Programs"/>
            </Helmet>

            <div className="blockwrap hero">
                <div className="container">
                    <div className="content">
                        <div className="block">
                            <h1>Security Program<br className="mobile"/></h1>
                            <p>
                                <strong>We take security seriously.</strong><br/><br/>
                                Protecting customer and consumer data is at the core of what we do. Spade’s information
                                security program is designed to meet or exceed industry standards with a variety of
                                controls to keep your information safe. <br/><br/>
                                Looking to report a security concern or have questions about Spade’s security? Please
                                contact <a href="mailto:security@spade.com">security@spade.com</a>.
                            </p>
                        </div>
                        <div className="block">
                            <h2>Secure Data</h2>
                            <p>
                                Spade encrypts all of its data at rest and in transit for all of our customers. We
                                utilize industry standard techniques and leverage AWS’ robust and well tested cloud
                                infrastructure platform to deploy our services securely and confidently. Our data
                                protection methods comply with SOC2 standards to ensure customer and company data as
                                well as sensitive information is protected at all times.
                            </p>
                        </div>
                        <div className="block">
                            <h2>Secure Development</h2>
                            <p>
                                All development projects at Spade follow secure development lifecycle principles,
                                including security design reviews for new development, secure development training for
                                all engineers, and software development conducted in line with&nbsp;
                                <a href="https://owasp.org/www-project-top-ten/" className='main'>OWASP Top 10</a>
                                &nbsp;recommendations for web application security. We use tools such as ECR Container scans,
                                Snyk container scans and dependency scans, SonarCloud, and GuardDuty to ensure we
                                maintain high quality code and avoid vulnerable packages. We also define all of our
                                infrastructure as code, which means all of our infrastructure definitions are held to
                                the same standards as our code.
                            </p>
                        </div>
                        <div className="block">
                            <h2>Secure Testing</h2>
                            <p>
                                Spade deploys internal and external penetration testing as well as vulnerability
                                scanning of all systems on a regular basis. All new systems and services are scanned
                                prior to being deployed to production.
                            </p>
                        </div>
                        <div className="block">
                            <h2>Secure Personnel</h2>
                            <p>
                                Spade takes the security of its data and that of its clients and customers seriously and
                                ensures that only vetted personnel are given access to their resources. We embed the
                                culture of security into our business by conducting employee security training & testing
                                using current and emerging techniques and attack vectors.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}
export default Security;