import React from 'react';
import './style.scss';

const JoinUs = (props) => {

    return (
        <div className='blockwrap join-us'>
            <div className='container'>
                <div className='content'>
                    <hr />
                    <div className='join-us-section'>

                        <div className='block-1'>
                            <h2>Join us</h2>
                        </div>

                        <div className='block-2'>
                            <h2>Help build the top<br className='mobile' /> transaction <br />enrichment API.</h2>
                            <p>Spade is a fast-growing company with a passion for creating exceptional products that support exceptional fintech solutions.  We value empathy, curiosity, and collaboration in our day-to-day work and beyond, and we’re building an intentional team with diverse backgrounds, opinions, and experiences. We’re remote-first but always connected, with a can-do culture that encourages team members to take pride in and ownership over their work.</p>
                            <a href='https://boards.greenhouse.io/spade' className='btn-general blue' target="_blank" rel="noreferrer">View Positions</a>
                        </div>
                       
                    </div>
                    
                </div>
            </div>
        </div>

    )
}
export default JoinUs;