import React from 'react';
import './style.scss';
import imgJeffTerry from './i/jeff-terry.jpg';
import imgTessBloch from './i/tess-bloch.jpg';

const OurTeam = (props) => {

    return (
      <div className='blockwrap our-team'>
          <div className='container'>
              <div className='content'>

                  <div className="details-wrap">

                      <div className="infobox">
                          <div className="img-title-wrap">
                              <img src={imgTessBloch} alt="placeholder" />
                              <div className='title-wrap'>
                                  <h3>Tess Bloch,</h3>
                                  <h4>Head of Operations</h4>
                              </div>
                          </div>
                          <p>"What I love most about Spade is the people – it’s incredibly rewarding to work with such an inspiring, driven, and talented team. Each and every person embodies our values of leading with empathy, bringing joy to the journey, forging new paths, succeeding together, and being intentionally inclusive, and it’s created a truly unique culture I’m grateful to be a part of."</p>
                      </div>

                      <div className="infobox">
                          <div className="img-title-wrap">
                              <img src={imgJeffTerry} alt="placeholder" />
                              <div className='title-wrap'>
                                  <h3>Jeff Terry,</h3>
                                  <h4>Software Engineer</h4>
                              </div>
                          </div>
                          <p>"I joined Spade because we’re solving one of the toughest problems in fintech – our product enables innovators to build the future of finance, which means there's no limit to how much impact we can have. I love getting to build from the ground up while tackling the challenges that come with scaling quickly, and to do it with such a fun and talented team."</p>
                      </div>
                  </div>



              </div>
          </div>

      </div>

    )
}
export default OurTeam;