import React from 'react';
import './style.scss';
import Wrapper from '../../../templates/Wrapper/Wrapper';
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";

const PrivacyPolicy = (props) => {

    return (
        <Wrapper addClass="privacy-terms" {...props}>

            <Helmet>
                <title>Spade | Privacy Policy</title>
                <meta name="description" content="Learn more about Spade's Privacy Policy"/>
            </Helmet>

            <div className="blockwrap hero">
                <div className="container">
                    <div className="content">
                        <div className="block">
                            <h1>Spade Privacy Policy<br className="mobile"/></h1>
                            <p>
                                <i>Last Updated: Aug 1st 2023</i><br/><br/>

                                Spade Data Inc. (“Spade,” “we,” “our,” and/or “us”) values the privacy of individuals
                                who use our website, including&nbsp;
                                <a href="https://spade.com" className='main'>www.spade.com</a> and&nbsp;
                                <a href="https://spade.dev" className='main'>www.spade.dev</a> (our “Website”).
                                This privacy policy (the “Privacy Policy”) explains how we collect, use, and share
                                personal information from users of our Website (“you” or “your”). By using our Website,
                                you agree to the collection, use, disclosure, and procedures described in this Privacy
                                Policy. Beyond the Privacy Policy, your use of our Website is also subject to our&nbsp;
                                <Link to="/terms">Terms of Service</Link>
                            </p>
                        </div>
                        <div className="block">
                            <p>
                                <br/><u><strong>Personal Information We Collect</strong></u><br/><br/>

                                We may collect a variety of personal information from or about you or your devices from
                                various sources, as described below.<br/><br/>

                                <strong>A. Information You Provide to Us</strong><br/><br/>

                                <strong>Learn More.</strong> When you request a demo, we may ask you for
                                your name, position, company website, email address, and other information.<br/>

                                <strong>Dashboard.</strong> When you use the dashboard via the Website, we may ask you
                                for your email address and other information.<br/>

                                <strong>Communications.</strong> If you contact us directly, we may receive personal
                                information about you. For example, when you contact us for more information about
                                Spade, we may receive your name, email address, the contents of a message or attachments
                                that you may send to us, and other information you choose to provide.<br/>

                                <strong>Careers.</strong> If you decide that you wish to apply for a job with us, you
                                may submit your contact information and your resume online. We will collect the
                                information you choose to provide on your resume, such as your education and employment
                                experience. You may also apply through LinkedIn. If you do so, we will collect the
                                information you make available to us on LinkedIn.<br/><br/>

                                <strong>B. Information We Collect When You Use Our Website</strong><br/><br/>

                                <strong>Device Information.</strong> We receive information about the device and
                                software you use to
                                access our Website, including IP address, web browser type, and operating system
                                version.<br/>

                                <strong>Usage Information.</strong> To help us understand how you use our Website and to
                                help us improve it, we automatically receive information about your interactions with
                                our Website like the pages or other content you view and the dates and times of your
                                visits.<br/>

                                <strong>Information from Cookies and Similar Technologies.</strong> We and our
                                third-party partners collect personal information using cookies, pixel tags, or similar
                                technologies. Our third-party partners, such as analytics and advertising partners,
                                may use these technologies to collect information about your online activities over time
                                and across different services. Cookies are small text files containing a string of
                                alphanumeric characters. We may use both session cookies and persistent cookies. A
                                session cookie disappears after you close your browser. A persistent cookie remains
                                after you close your browser and may be used by your browser on subsequent visits to our
                                Website.<br/><br/>

                                Please review your web browser’s “Help” file to learn the proper way to modify your
                                cookie settings. Please note that if you delete or choose not to accept cookies from our
                                Website, you may not be able to utilize the features of our Website to their fullest
                                potential.<br/><br/>

                                <u><strong>How We Use the Information We Collect</strong></u><br/><br/>

                                We use the information we collect:<br/>
                                <p className="tab">
                                    <ul>
                                        <li>
                                            To provide, maintain, improve, and enhance our Website;
                                        </li>
                                        <li>
                                            To understand and analyze how you use our Website and develop new products,
                                            services, features, and functionality;
                                        </li>
                                        <li>
                                            To communicate with you, provide you with updates and other information
                                            relating to our Website, provide information that you request, respond to
                                            comments and questions, and otherwise provide customer support;
                                        </li>
                                        <li>
                                            For marketing and advertising purposes, such as developing and providing
                                            promotional and advertising materials that may be relevant, valuable, or
                                            otherwise of interest to you;
                                        </li>
                                        <li>
                                            To find and prevent fraud, and respond to trust and safety issues that may
                                            arise;
                                        </li>

                                        <li>
                                            For compliance purposes, including enforcing our Terms of Service or other
                                            legal rights, or as may be required by applicable laws and regulations or
                                            requested by any judicial process or governmental agency; and
                                        </li>
                                        <li>
                                            For other purposes for which we provide notice at the time the personal
                                            information is collected.
                                        </li>
                                    </ul>
                                </p>
                                <br/>

                                <u><strong>How We Share the Information We Collect</strong></u><br/><br/>

                                <strong>Partners and Affiliates.</strong> We may share any information we receive with
                                our partners, corporate affiliates, parents, or subsidiaries for any purpose described
                                in this Privacy Policy.<br/>

                                <strong>Vendors and Service Providers.</strong> We may share any information we receive
                                with vendors and service providers retained in connection with the provision of our
                                Website.<br/>

                                <strong>Analytics Partners.</strong> We use analytics services such as Google Analytics
                                to collect and process certain analytics data. These services may also collect
                                information about your use of other websites, apps, and online resources. You can learn
                                more about Google’s practices by visiting
                                https://www.google.com/policies/privacy/partners/. To help us understand how you use our
                                Website and to help us improve it, we automatically receive information about your
                                interactions with our Website, like the pages or other content you view and the dates
                                and times of your visits.<br/>

                                <strong>As Required by Law and Similar Disclosures.</strong> We may access, preserve,
                                and disclose your personal information if we believe doing so is required or appropriate
                                to: (a) comply with law enforcement requests and legal process, such as a court order or
                                subpoena; (b) respond to your requests; or (c) protect your, our, or others’ rights,
                                property, or safety. For the avoidance of doubt, the disclosure of your personal
                                information may occur if you post any objectionable content on or through our Website.
                                <br/>

                                <strong>Merger, Sale, or Other Asset Transfers.</strong> We may transfer or disclose
                                your personal information to service providers, advisors, potential transactional
                                partners, or other third parties in connection with the consideration, negotiation, or
                                completion of a corporate transaction in which we are acquired by or merged with another
                                company, or we sell, liquidate, or transfer all or a portion of our assets.<br/>

                                <strong>Consent.</strong> We may also disclose personal information from or about you or
                                your devices with your permission.<br/><br/>

                                <u><strong>Your Choices</strong></u><br/><br/>

                                <strong>Marketing Communications.</strong> You can unsubscribe from our promotional
                                emails via the link provided in the emails. Even if you opt out of receiving promotional
                                messages from us, you will continue to receive administrative messages from us.<br/>

                                <strong>Do Not Track.</strong> There is no accepted standard on how to respond to Do Not
                                Track signals, and we do not respond to such signals.<br/><br/>

                                <u><strong>Third Parties</strong></u><br/><br/>

                                Our Website may contain links to other websites, products, or services that we do not
                                own or operate. We are not responsible for the privacy practices of these third parties.
                                Please be aware that this Privacy Policy does not apply to your activities on these
                                third-party services or any information you disclose to these third parties. We
                                encourage you to read their privacy policies before providing any information to
                                them.<br/><br/>

                                <u><strong>Security</strong></u><br/><br/>

                                We make reasonable efforts to protect your personal information by using physical and
                                electronic safeguards designed to improve the security of the personal information we
                                maintain. However, as no electronic transmission or storage of information can be
                                entirely secure, we can make no guarantees as to the security or privacy of your
                                personal information.<br/><br/>

                                <u><strong>Children’s Privacy</strong></u><br/><br/>

                                We do not knowingly collect, maintain, or use personal information from children under
                                13 years of age, and no parts of our Website are directed to children. If you learn that
                                a child has provided us with personal information in violation of this Privacy Policy,
                                you may alert us at security@spade.com.
                                International Visitors

                                Our Website is hosted in the United States (“U.S.”) and intended for visitors located
                                within the U.S. If you choose to use our Website from the European Union or other
                                regions of the world with laws governing data collection and use that may differ from
                                U.S. law, then please note that you are transferring your personal information outside
                                of those regions to the U.S. for storage and processing. Also, we may transfer your
                                personal information from the U.S. to other countries or regions in connection with
                                storage and processing of data, fulfilling your requests, and operating our Website. By
                                providing any information, including personal information, on or through our Website,
                                you consent to such transfer, storage, and processing.<br/><br/>

                                <u><strong>Changes to This Privacy Policy</strong></u><br/><br/>

                                We will post any adjustments to the Privacy Policy on this page, and the revised version
                                will be effective when it is posted. If we materially change the ways in which we use or
                                share personal information previously collected from you through our Website, we will
                                notify you through our Website, by email, or other communication.<br/><br/>

                                <u><strong>Contact Information</strong></u><br/><br/>

                                If you have any questions, comments, or concerns about our processing activities, please
                                email us at security@spade.com or write to us at:<br/><br/>

                                Spade Data Inc.<br/>
                                125 West 25th Street, 2nd Floor<br/>
                                New York, NY 10001
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default PrivacyPolicy;