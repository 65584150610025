import React from 'react';
import './style.scss';

const ArrowAngled = (props) => {

    return (

        <svg id="Layer_1" className="arrow-angled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.22 39.22">
            <defs></defs>
            <polygon className="cls-1" points="0 35.04 29.08 5.96 5.96 5.96 5.98 0 39.18 .04 39.22 33.24 33.26 33.26 33.26 10.14 4.18 39.22 0 35.04"/>
        </svg>
        
    )
}
export default ArrowAngled;