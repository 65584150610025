import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
//import image from './i/placeholder.jpg';
import banking from 'assets/images/banking.png';
import customer from 'assets/images/customer.png';
import fraud from 'assets/images/fraud.png';
import underwriting from 'assets/images/underwriting.png';

// const BankingAnimation = lazy(() => import('components/BankingAnimation/BankingAnimation'));
// const CustomerAnimation = lazy(() => import('components/CustomerAnimation/CustomerAnimation'));
// const FraudAnimation = lazy(() => import('components/FraudAnimation/FraudAnimation'));
// const UnderwritingAnimation = lazy(() => import('components/UnderwritingAnimation/UnderwritingAnimation'));



const PurposeBuiltSolutions = (props) => {

    // const {isMobile} = props;

    return (
        <div className="blockwrap purpose-built-solutions">
            <div className="container">
                <div className="content">

                    <div className="section-title">
                        <h2>Purpose-built<br />transaction<br />enrichment<br />solutions</h2>
                        <Link to="/solutions" className="btn-general blue">Learn More</Link>
                    </div>

                    <div className='section-info'>

                        <div className="info-wrap">
                            <div className="info">
                                <img src={banking} alt="Enhance real-time authorization" />
                                {/*/!* {isMobile ? (*/}
                                {/*) : (*/}
                                {/*    <Suspense fallback={<img src={banking} alt="Enhance real-time authorization" />}>*/}
                                {/*        <BankingAnimation maxHeight={295} />*/}
                                {/*    </Suspense>*/}
                                {/*)} *!/*/}
                                <h3>Enhance real-time<br /> authorization</h3>
                                <p>Spade returns clean, enriched data faster, so you can improve your real-time authorization capabilities, implement granular spend controls, and optimize your user experience.</p>
                            </div>

                            <div className="info">
                                <img src={customer} alt="Understand your customers." />
                                {/* {isMobile ? (
                                ) : (
                                    <Suspense fallback={<img src={customer} alt="Understand your customers." />}>
                                        <CustomerAnimation maxHeight={295} />
                                    </Suspense>
                                )} */}
                                <h3>Understand your<br /> customers</h3>
                                <p>Spade provides greater visibility into customer spending, allowing you to build tailored customer experiences that boost engagement and lifetime value.</p>
                            </div>
                        </div>
                       
                        <div className="info-wrap">
                            <div className="info">
                                <img src={fraud} alt="Stop fraud in its tracks." />
                            {/* {isMobile ? (
                                ) :( 
                                    <Suspense fallback={<img src={fraud} alt="Stop fraud in its tracks." />}>
                                        <FraudAnimation maxHeight={295} />
                                    </Suspense>
                                )} */}
                                <h3>Stop fraud in its tracks</h3>
                                <p>With Spade’s exact geographic data, categorizations, and merchant details, you can advance your fraud detection models, monitor transactions more effectively, and shut down suspicious purchases.</p>
                            </div>

                            <div className="info">
                                <img src={underwriting} alt="Lend with confidence." />
                            {/* {isMobile ? (
                                ) : (
                                    <Suspense fallback={<img src={underwriting} alt="Lend with confidence." />}>
                                        <UnderwritingAnimation maxHeight={295} />
                                    </Suspense>
                                )} */}
                                <h3>Leverage fraud insights</h3>
                                <p>Use Spade's merchant insights to detect and prevent transactions stemming from potentially risky merchants to protect customers, reduce chargebacks, and more.</p>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </div>
             

        </div>

    )
}
export default PurposeBuiltSolutions;
