import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { Image, Group } from "react-konva";
import useImage from "use-image";

const TopCard = () => {
    const [image] = useImage("/animation-images/Hero/top-card.png");
    return (
        <Group x={-184.5} y={-115.5}>
            <Image image={image} />
        </Group>
    );
};

const BottomCard = () => {
    const [image] = useImage("/animation-images/Hero/bottom-card.png");
    return (
        <Group x={-184.5} y={-115.5}>
            <Image image={image} />
        </Group>
    );
};

const CardsAnimation = ({inView}) => {

    const tl = useRef();

    const topCardRef = useRef();
    const topCardFloatRef = useRef();
    const bottomCardRef = useRef();
    const bottomCardFloatRef = useRef();
    const topCardFloattl = useRef();
    const bottomCardFloattl = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            tl.current = gsap.timeline().add([
                gsap.fromTo(
                    bottomCardRef.current,
                    { y: 175, rotation: 5 },
                    { y: 0, rotation: 0, duration: 4, ease: "sine.inOut" }
                ),
                gsap.fromTo(
                    topCardRef.current,
                    { y: 240, x: 10, rotation: 5 },
                    {
                        y: 0,
                        x: 0,
                        rotation: 0,
                        duration: 4,
                        ease: "sine.inOut",
                    }
                ),
            ]);

            topCardFloattl.current = gsap
                .timeline({ repeat: -1 })
                .fromTo(
                        topCardFloatRef.current,
                        { y: 10 },
                        { y: -10, duration: 1.5, ease: "sine.inOut" }
                    )
                .fromTo(
                        topCardFloatRef.current,
                        { y: -10 },
                        { y: 10, duration: 1.5, ease: "sine.inOut" }
                    );
            bottomCardFloattl.current = gsap
                .timeline({ repeat: -1 })
                .fromTo(
                        bottomCardFloatRef.current,
                        { y: 10 },
                        { y: -10, duration: 1.6, ease: "sine.inOut" }
                    )
                .fromTo(
                        bottomCardFloatRef.current,
                        { y: -10 },
                        { y: 10, duration: 1.6, ease: "sine.inOut" }
                    );
        });

        if (inView) {
            tl.current.play();
            topCardFloattl.current.play();
            bottomCardFloattl.current.play();
        } else {
            tl.current.pause(0.1);
            topCardFloattl.current.pause(0.1);
            bottomCardFloattl.current.pause(0.1);
        }

        return () => ctx.revert();
    },[inView]);

    return (
        <>
            <Group x={343.5} y={223}>
                <Group ref={bottomCardFloatRef}>
                    <Group ref={bottomCardRef}>
                        <BottomCard />
                    </Group>
                </Group>
            </Group>
            <Group x={334.5} y={145.5}>
                <Group ref={topCardFloatRef}>
                    <Group ref={topCardRef}>
                        <TopCard />
                    </Group>
                </Group>
            </Group>
        </>
    );
};

export default CardsAnimation;
