import React from 'react';
import './style.scss';
import Wrapper from '../../../templates/Wrapper/Wrapper';
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";

const TermsOfService = (props) => {

    return (
        <Wrapper addClass="privacy-terms" {...props}>

            <Helmet>
                <title>Spade | Terms of Service</title>
                <meta name="description" content="Learn more about Spade's Terms of Service"/>
            </Helmet>

            <div className="blockwrap hero">
                <div className="container">
                    <div className="content">
                        <div className="block">
                            <h1>Spade Terms of Use<br className="mobile"/></h1>
                            <p>
                                <i>Last Updated: Aug 29th 2022</i><br/><br/>

                                Welcome, and thank you for your interest in Spade Data, Inc. (“Spade,” “we,” or “us”)
                                and our website at <a href="https://spade.com" className='main'>www.spade.com</a>,
                                including subdomains of that website (collectively, the “Site”). These Terms of Use
                                are a legally binding contract between you and Spade regarding your use of the Site.
                                Please note that your use of Spade’s data services, such as the Spade API and Spade
                                customer platform, is governed by the customer agreement entered into between you
                                and Spade (which may be the Spade Master Service and API License Agreement and the
                                Order Form issued pursuant to that agreement). Please review your customer agreement
                                for the terms and conditions related to your use of the applicable Spade services.
                            </p>
                        </div>
                        <div className="block">
                            <p>
                                <br/><br/>PLEASE READ THE FOLLOWING TERMS CAREFULLY.<br/><br/>

                                BY ACCESSING OR USING THE SITE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A
                                CONDITION TO YOUR USE OF THE SITE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND
                                CONDITIONS, INCLUDING THE PRIVACY POLICY (DEFINED BELOW) (TOGETHER, THESE “TERMS”). IF
                                YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION
                                TO USE THE SITE. YOUR USE OF THE SITE, AND SPADE’S PROVISION OF THE SITE TO YOU,
                                CONSTITUTES AN AGREEMENT BY SPADE AND BY YOU TO BE BOUND BY THESE TERMS.<br/><br/><br/>
                            </p>
                        </div>
                        <div className="block">
                            <p>
                                1. <strong>Overview.</strong> The Site provides information about Spade and our products
                                and services,
                                including without limitation our transaction enrichment services.<br/><br/>
                                2. <strong>Eligibility.</strong> You must be at least 18 years old to use the Site. By
                                agreeing to these
                                Terms, you represent and warrant to us that: (a) you are at least 18 years old; (b) you
                                have not previously been suspended or removed from the Site or any other service
                                provided by Spade; and (c) your use of the Site is in compliance with any and all
                                applicable laws and regulations. If you are an entity, organization, or company, the
                                individual accepting these Terms on your behalf represents and warrants that they have
                                authority to bind you to these Terms and you agree to be bound by these Terms.<br/><br/>
                                3. <strong>Account and Registration.</strong> To access certain features of the Site,
                                you must register for
                                an account. When you register for an account, you may be required to provide us with
                                some information about yourself, such as your email address or other contact
                                information. You agree that the information you provide to us is accurate, complete, and
                                not misleading, and that you will keep it accurate and up to date at all times. When you
                                register, you will be asked to create a password. You are solely responsible for
                                maintaining the confidentiality of your account and password, and you accept
                                responsibility for all activities that occur under your account. If you believe that
                                your account is no longer secure, then you should immediately notify us at&nbsp;
                                <a href="mailto:help@spade.com">help@spade.com</a>.<br/><br/>
                                4. <strong>Demo Request.</strong> You may request a demo by filling out our online request form ("Learn More"). When you submit an Demo Request, you will be asked to provide information such as your full name, title, company website, business email, and a description of the product you plan to build using our API. We will review the information you submitted and may reach out to you if we approve your Demo Request. Information submitted in connection with your Demo Request will be processed in accordance with our Privacy Policy, and your use of our API (and all information, data, input, and outputs you receive or provide in connection with such use) will be governed by the applicable terms and conditions Spade makes available to you when we grant you access to the API. Your access to the API is expressly conditioned on you agreeing to such terms and conditions.<br/><br/>
                                5. <strong>Licenses</strong><br/>
                                <p className="tab">
                                    1. <strong>Limited License.</strong> Subject to your complete and ongoing compliance
                                    with these Terms, Spade grants you, solely for your personal, use, a limited,
                                    non-exclusive, non-transferable, non-sublicensable, revocable license to access and
                                    use the Site.<br/><br/>
                                    2. <strong>License Restrictions.</strong> Except and solely to the extent such a
                                    restriction is impermissible under applicable law, you may not: (a) reproduce,
                                    distribute, publicly display, or publicly perform the Site; (b) make modifications
                                    to
                                    the Site; or (c) interfere with or circumvent any feature of the Site, including any
                                    security or access control mechanism. If you are prohibited under applicable law
                                    from using the Site, you may not use it.<br/><br/>
                                    3. <strong>Information You Submit.</strong> Do not submit any information or other
                                    materials through the Site that (a) you consider confidential or proprietary;
                                    (b) infringes or violates any third party intellectual property right or rights of
                                    privacy or publicity, or (c) is otherwise harmful or objectionable. If you choose
                                    to provide input and suggestions regarding problems with or proposed modifications
                                    or improvements to the Site (“Feedback”), then you do so on a non-confidential basis
                                    (regardless of any designation or indication to the contrary in the submitted
                                    information or any accompanying correspondence) and you hereby grant Spade an
                                    unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free
                                    transferable, sublicensable right to exploit the Feedback in any manner and for any
                                    purpose, including to improve the Site and create other products and services.
                                    <br/><br/>
                                </p>
                                6. <strong>Ownership; Proprietary Rights.</strong> The Site is owned and operated by
                                Spade. The visual interfaces, graphics, design, compilation, information, data,
                                documents, computer code (including source code or object code), products, software,
                                services, and all other elements of the Site (“Materials”) provided by Spade are
                                protected by intellectual property and other laws. All Materials included in the Site
                                are the property of Spade or its third-party licensors. Except as expressly authorized
                                by Spade, you may not make use of the Materials. Spade reserves all rights to the
                                Materials not granted expressly in these Terms.<br/><br/>
                                7. <strong>Linked Websites.</strong> The Site may contain links to third party websites.
                                Linked websites are not under Spade’s control, and Spade is not responsible for their
                                content. Please be sure to review the terms of use and privacy policy of any third-party
                                services before you share any content or information about yourself with such
                                third-party services. Once sharing occurs, Spade will have no control over the
                                information that has been shared.<br/><br/>
                                8. <strong>Prohibited Conduct.</strong> BY USING THE SITE YOU AGREE NOT TO:
                                <p className="tab">
                                    a. use the Site for any illegal purpose or in violation of any local, state,
                                    national, or international law;<br/>
                                    b. violate, or encourage others to violate, any right of a third party, including by
                                    infringing or misappropriating any third-party intellectual property right;<br/>
                                    c. interfere with security-related features of the Site, including by: (i) disabling
                                    or circumventing features that prevent or limit use or copying of any content;
                                    or (ii) reverse engineering or otherwise attempting to discover the source code of
                                    any portion of the Site except to the extent that the activity is expressly
                                    permitted by applicable law;<br/>
                                    d. interfere with the operation of the Site or any user’s enjoyment of the Site,
                                    including by uploading or otherwise disseminating any virus, adware, spyware, worm,
                                    or other malicious code or interfering with or disrupting any network, equipment, or
                                    server connected to or used to provide the Site;<br/>
                                    e. perform any fraudulent activity including impersonating any person or entity, or
                                    claiming a false affiliation;<br/>
                                    f. sell or otherwise transfer the access granted under these Terms or any Materials
                                    or any right or ability to view, access, or use any Materials; or<br/>
                                    g. attempt to do any of the acts described in this Section 8 or assist or permit any
                                    person in engaging in any of the acts described in this Section 8.<br/><br/>
                                </p>
                                9. <strong>Modification of these Terms.</strong> We reserve the right to change these
                                Terms on a going-forward basis at any time. Please check these Terms periodically for
                                changes. Except as expressly permitted in this Section 9, these Terms may be amended
                                only by a written agreement signed by authorized representatives of the parties to these
                                Terms. Disputes arising under these Terms will be resolved in accordance with the
                                version of these Terms that was in effect at the time the dispute arose.<br/><br/>
                                10. <strong>Modification of the Site.</strong> Spade reserves the right to modify or
                                discontinue the Site at any time (including by limiting or discontinuing certain
                                features of the Site), temporarily or permanently, without notice to you. Spade will
                                have no liability for any change to the Site or any suspension or termination of your
                                access to or use of the Site.<br/><br/>
                                11. <strong>Term.</strong> These Terms are effective beginning when you accept the Terms
                                or first access, or use the Site, and ending when terminated as described in this
                                Section 11. If you violate any provision of these Terms, your authorization to access
                                the Site and these Terms automatically terminate. In addition, Spade may, at its sole
                                discretion, terminate these Terms, or suspend or terminate your access to the Site, at
                                any time for any reason or no reason, with or without notice. Upon termination of these
                                Terms: (a) your license rights will terminate and you must immediately cease all use of
                                the Site; (b) you will no longer be authorized to access the Site; and (c) Sections 5.3,
                                6, 11, 12, 13, 14, and 15 will survive.<br/><br/>
                                12. <strong>Indemnity.</strong> To the fullest extent permitted by law, you are
                                responsible for your use of the Site, and you will defend and indemnify Spade and its
                                officers, directors, employees, consultants, affiliates, subsidiaries and agents
                                (together, the “Spade Entities”) from and against every claim brought by a third party,
                                and any related liability, damage, loss, and expense, including reasonable attorneys’
                                fees and costs, arising out of or connected with: (a) your unauthorized use of, or
                                misuse of, the Site; (b) your violation of any portion of these Terms, any
                                representation, warranty, or agreement referenced in these Terms, or any applicable law
                                or regulation; (c) your violation of any third party right, including any intellectual
                                property right or publicity, confidentiality, other property, or privacy right; or (d)
                                any dispute or issue between you and any third party. We reserve the right, at our own
                                expense, to assume the exclusive defense and control of any matter otherwise subject to
                                indemnification by you (without limiting your indemnification obligations with respect
                                to that matter), and in that case, you agree to cooperate with our defense of those
                                claims.<br/><br/>
                                13. <strong>Disclaimers; No Warranties</strong>
                                <p className="tab">
                                    1. THE SITE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SITE ARE PROVIDED
                                    “AS IS” AND ON AN “AS AVAILABLE” BASIS. SPADE DISCLAIMS ALL WARRANTIES OF ANY KIND,
                                    WHETHER EXPRESS OR IMPLIED, RELATING TO THE SITE AND ALL MATERIALS AND CONTENT
                                    AVAILABLE THROUGH THE SITE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY,
                                    FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND
                                    (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. SPADE DOES NOT
                                    WARRANT THAT THE SITE OR ANY PORTION OF THE SITE, OR ANY MATERIALS OR CONTENT
                                    OFFERED THROUGH THE SITE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES,
                                    OR OTHER HARMFUL COMPONENTS, AND SPADE DOES NOT WARRANT THAT ANY OF THOSE ISSUES
                                    WILL BE CORRECTED.<br/><br/>
                                    2. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SITE
                                    OR SPADE ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SITE WILL CREATE
                                    ANY WARRANTY REGARDING ANY OF THE SPADE ENTITIES OR THE SITE THAT IS NOT EXPRESSLY
                                    STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM
                                    THE SITE OR YOUR USE OF THE SITE. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION
                                    OF THE SITE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY
                                    DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN
                                    CONNECTION WITH THE SITE) OR ANY LOSS OF DATA.<br/><br/>
                                    3. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST
                                    EXTENT PERMITTED BY LAW. Spade does not disclaim any warranty or other right that
                                    Spade is prohibited from disclaiming under applicable law.<br/><br/>
                                </p>
                                14. <strong>Limitation of Liability</strong>
                                <p className="tab">
                                    1. TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE SPADE ENTITIES BE
                                    LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
                                    DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE
                                    LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
                                    ACCESS OR USE, THE SITE OR ANY MATERIALS OR CONTENT ON THE SITE, WHETHER BASED ON
                                    WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY,
                                    AND WHETHER OR NOT ANY SPADE ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF
                                    DAMAGE.<br/><br/>
                                    2. TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE SPADE
                                    ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY
                                    INABILITY TO USE ANY PORTION OF THE SITE OR OTHERWISE UNDER THESE TERMS, WHETHER IN
                                    CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO $100.<br/><br/>
                                    3. EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY,
                                    DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE
                                    THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL
                                    ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
                                    SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN
                                    THIS SECTION 14 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL
                                    PURPOSE.<br/><br/>
                                </p>
                                15. <strong>Miscellaneous</strong>
                                <p className="tab">
                                    1. <strong>General Terms.</strong> These Terms, together with the Privacy Policy
                                    available at&nbsp;
                                    <Link to="/privacy">Privacy Policy</Link> (the “Privacy Policy”) and any Additional
                                    Terms (defined below), are the entire and exclusive understanding and agreement
                                    between you and Spade regarding your use of the Site. You may not assign or transfer
                                    these Terms or your rights under these Terms, in whole or in part, by operation of
                                    law or otherwise, without our prior written consent. We may assign these Terms at
                                    any time without notice or consent. The failure to require performance of any
                                    provision will not affect our right to require performance at any other time after
                                    that, nor will a waiver by us of any breach or default of these Terms, or any
                                    provision of these Terms, be a waiver of any subsequent breach or default or a
                                    waiver of the provision itself. Use of section headers in these Terms is for
                                    convenience only and will not have any impact on the interpretation of any
                                    provision. Throughout these Terms the use of the word “including” means “including
                                    but not limited to”. If any part of these Terms is held to be invalid or
                                    unenforceable, the unenforceable part will be given effect to the greatest extent
                                    possible, and the remaining parts will remain in full force and effect.<br/><br/>
                                    2. <strong>Governing Law.</strong> These Terms are governed by the laws of the State
                                    of New York without regard to conflict of law principles that would result in the
                                    application of the laws of another jurisdiction. You and Spade submit to the
                                    personal and exclusive jurisdiction of the state courts and federal courts located
                                    within New York City, New York for resolution of any lawsuit or court proceeding
                                    permitted under these Terms.<br/><br/>
                                    3. <strong>Privacy Policy.</strong> Please read the Privacy Policy carefully for
                                    information relating to our collection, use, storage, disclosure of your personal
                                    information. The Privacy Policy is incorporated by this reference into, and made a
                                    part of, these Terms.<br/><br/>
                                    4. <strong>Additional Terms.</strong> Your use of the Site is subject to all
                                    additional terms, policies, rules, or guidelines applicable to the Site or certain
                                    features of the Site that we may post on or link to from the Site or that you agree
                                    to in connection with certain technology or services made available to you by or on
                                    behalf of Spade (the “Additional Terms”). All Additional Terms are incorporated by
                                    this reference into, and made a part of, these Terms.<br/><br/>
                                    5. <strong>Consent to Electronic Communications.</strong> By using the Site, you
                                    consent to receiving certain electronic communications from us. We may send you
                                    emails concerning our products and services, as well as those of third parties.
                                    You may opt out of promotional emails by following the unsubscribe instructions in
                                    the promotional email itself. You agree that any notices, agreements, disclosures,
                                    or other communications that we send to you electronically will satisfy any legal
                                    communication requirements, including that those communications be in
                                    writing.<br/><br/>
                                    6. <strong>Contact Information.</strong> The Site is offered by Spade Data, Inc.,
                                    located at 125 West 25th Street 2nd Floor, New York, NY 10001. You may contact us
                                    by sending correspondence to that address or by emailing us at
                                    <a href="mailto:help@spade.com">help@spade.com</a>.<br/><br/>
                                    7. <strong>Notice to California Residents.</strong> If you are a California
                                    resident, under California Civil Code Section 1789.3, you may contact the Complaint
                                    Assistance Unit of the Division of Consumer Services of the California Department of
                                    Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
                                    California 95834, or by telephone at (800) 952-5210 in order to resolve a complaint
                                    regarding the Site or to receive further information regarding use of the Site.
                                    <br/><br/>
                                    8. <strong>No Support.</strong> We are under no obligation to provide support for
                                    the Site. In instances where we may offer support, the support will be subject to
                                    published policies.<br/><br/>
                                    9. <strong>International Use.</strong> The Site is intended for visitors located
                                    within the United States. We make no representation that the Site is appropriate or
                                    available for use outside of the United States. Access to the Site from countries or
                                    territories or by individuals where such access is illegal is prohibited.<br/><br/>
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}
export default TermsOfService;