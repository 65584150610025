import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { Image, Group } from "react-konva";
import useImage from "use-image";

const Block = ({inView}) => {
    const blocktl = useRef();
    const blockRef = useRef();
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            blocktl.current = gsap
                .timeline({ repeat: -1 })
                .to(blockRef.current, {
                    rotation: 360 * [1, -1][Math.round(Math.random())],
                    duration: Math.random() * 5 + 10,
                    ease: "none",
                });
        });

        if(inView) {
            blocktl.current.play();
        } else {
            blocktl.current.pause(0.1);
        }

        return () => ctx.revert();
    }, [inView]);

    const [image] = useImage("/animation-images/Hero/block.png");

    return (
        <Group ref={blockRef}>
            <Group x={-42} y={-41}>
                <Image image={image} />
            </Group>
        </Group>
    );
};

export default Block;