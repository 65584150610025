import React, { useEffect } from 'react';
import './style.scss';
import { useLocation } from "react-router-dom";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Wrapper = (props) => {
    let {children, addClass} = props;
    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({top: 0, left: 0, behavior: 'instant' });
        return () => {
            console.log('cleanup');
        }
    },[pathname]);


    return (

        <div className={`wrapper ${addClass}`}>
            <Header {...props} />
            {children}
            <Footer  {...props} />
        </div>

    )
}
export default Wrapper;