import React from 'react';
import './style.scss';
import image1 from './i/RealData@2x.png';
import image2 from './i/BroaderCoverage@2x.png';
import image3 from './i/DeeperInsight@2x.png';
import image4 from './i/RealTimeResponse@2x.png';
import image5 from './i/EasyImplementation@2x.png';
import image6 from './i/SecureSolutions@2x.png';

const SpadeDetails = (props) => {

    return (
        <div className="blockwrap spade-details">

            <div className="container">
                <div className="content">

                    <div className="details-wrap">
                        <div className="infobox">
                            <div className="img-title-wrap">
                                <img src={image1} alt="placeholder" />
                                <h3>Real<br />data</h3>
                            </div>
                            <p>
                            Leverage a ground-truth data network that matches transactions to actual merchant identities, instead of relying on scraping and speculation.
                            </p>
                        </div>

                        <div className="infobox">
                            <div className="img-title-wrap">
                                <img src={image2} alt="placeholder" />
                                <h3>Broader<br /> coverage</h3>
                            </div>
                            <p>Get complete, standardized data for >99.9% of merchants in the U.S., from nationwide chains to small local shops.</p>
                        </div>

                        <div className="infobox">
                            <div className="img-title-wrap">
                                <img src={image3} alt="placeholder" />
                                <h3>Deeper<br /> insights</h3>
                            </div>
                            <p>Go beyond MCC and NAICs codes to precise spend categories and geographic coordinates, plus additional fields like logo, website, business hours, and customer spending histories.</p>
                        </div>
                    </div>

                    <div className="details-wrap">
                        <div className="infobox">
                            <div className="img-title-wrap">
                                <img src={image4} alt="placeholder" />
                                <h3>Real-time<br />response</h3>
                            </div>
                            <p>
                            Have your transaction data processed, enhanced, and returned in under 50ms—well within card authorization windows and much faster than other enrichment engines.
                            </p>
                        </div>

                        <div className="infobox">
                            <div className="img-title-wrap">
                                <img src={image5} alt="placeholder" />
                                <h3>Easy<br />implementation</h3>
                            </div>
                            <p>Get up and running quickly with a simple, turnkey integration that can be completed in under an hour.</p>
                        </div>

                        <div className="infobox">
                            <div className="img-title-wrap">
                                <img src={image6} alt="placeholder" />
                                <h3>Secure<br /> solutions</h3>
                            </div>
                            <p>Partner with a SOC 2-certified company backed by seasoned investors and a team of experienced industry experts.</p>
                        </div>
                    </div>

                </div>
            </div>
              
        </div>

    )
}
export default SpadeDetails;