import React from 'react';
import './style.scss';

const SpadeLogo = (props) => {

    return (
   
       
<svg id="Layer_1" className={`spade-logo ${props.className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.99 66.39">
    <defs>
    {/* <style>.cls-1{fill:#fff;}</style> */}
    </defs>
    <g>
        <path className="cls-1" d="M8.91,47.26c.74,8.54,8.63,11.33,15.41,11.33s14.86-2.79,14.86-12.35c0-18.57-37.79-1.21-37.79-27.67C1.39,7.15,10.68-.18,24.05,0c11.33,.19,20.05,6.04,21.54,15.69l-8.63,1.58c-.84-7.15-6.87-10.03-12.91-10.03-6.41,0-13.37,2.79-13.37,11.33,0,16.62,37.79-.65,37.79,27.67,0,8.54-4.74,20.15-24.14,20.15C5.94,66.39,.84,56.92,0,48.75l8.91-1.49Z"/>
        <path className="cls-1" d="M102.26,22.47c0,13.83-8.91,21.54-23.77,21.54h-13.46v21.91h-9.19V.93h22.65c14.86,0,23.77,7.71,23.77,21.54Zm-23.77,13.93c9.66,0,14.58-5.85,14.58-13.93s-4.92-13.93-14.58-13.93h-13.46v27.85h13.46Z"/>
        <path className="cls-1" d="M228.95,33.89c0,22.1-14.95,32.5-33.05,32.5h-22V1.4h22c18.11,0,33.05,10.4,33.05,32.5Zm-33.05,24.79c12.91,0,23.49-7.24,23.49-24.79s-10.68-24.79-23.49-24.79h-12.81V58.68h12.81Z"/>
        <path className="cls-1" d="M279.03,9.66h-33.52V29.71h28.41v8.26h-28.41v20.15h33.52v8.26h-42.71V1.4h42.71V9.66Z"/>
        <polygon className="cls-1" points="166.85 1.4 144.86 66.39 135.47 66.39 148.16 26.93 122.79 26.93 135.47 66.39 126.08 66.39 104.09 1.4 114.73 1.4 120.33 19.13 150.62 19.13 156.22 1.4 166.85 1.4"/>
    </g>
    <g>
        <polygon className="cls-1" points="288.94 9.66 288.94 2.8 286.4 2.8 286.4 1.4 292.98 1.4 292.98 2.8 290.45 2.8 290.45 9.66 288.94 9.66"/>
        <polygon className="cls-1" points="300.47 9.66 300.47 5.21 298.77 9.66 297.49 9.66 295.79 5.21 295.79 9.66 294.27 9.66 294.27 1.4 295.79 1.4 298.13 7.57 300.47 1.4 301.99 1.4 301.99 9.66 300.47 9.66"/>
    </g>
</svg>

    )
}
export default SpadeLogo;