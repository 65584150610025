import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
// import image1 from './i/placeholder-1.jpg';
import AboutContent from './AboutContent/AboutContent';
import LogosContent from './LogosContent/LogosContent';
import OurInvestors from './OurInvestors/OurInvestors';
import OurTeam from './OurTeam/OurTeam';
import JoinUs from './JoinUs/JoinUs';
import { Helmet } from 'react-helmet-async';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';

const About = (props) => {
    const [isToggled, toggle] = useToggle(false);
    const {isMobile} = props;
    
    return (
        <Wrapper addClass="about" {...props}>
            
            <Helmet>
                <title>Spade | About Us</title>
                <meta name="description" content="Spade is the only transaction enrichment API backed by real data with near universal coverage." />
            </Helmet>

            <div className="blockwrap hero">

                <div className="container">
                    <div className="content">

                        <div className="block-1">
                            <h1>Transaction<br className="mobile" /> data<br className="desktop" /> that’s<br className="mobile" /> actionable</h1>
                            <p>We’re on a mission to power the future <br />of finance with superior financial data.</p>
                            <button onClick={toggle} className="btn-general api-access">Learn More</button>
                        </div>
                        
                    </div>
                </div>

            </div>

            <AboutContent isMobile={isMobile} />
            <LogosContent />
            <OurInvestors />
            <JoinUs />
            <OurTeam />

            {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
                <FormApiAccess />
            </Modal>}

        </Wrapper>
    )
}
export default About;