import { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { Group, Rect } from "react-konva";

const DataBitBlocksGroup = ({
    colors = ["red"],
    scales = [1.0],
    x = 0,
    y = 0,
    swapPos = false,
    inView
}) => {
    const dim = 25;

    const blocktl1 = useRef();
    const blocktl2 = useRef();
    const blockRef1 = useRef();
    const blockRef2 = useRef();
    const refs = [blockRef1, blockRef2];

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            blocktl1.current = gsap
                .timeline({ repeat: -1, delay: Math.random() })
                .fromTo(
                    blockRef1.current,
                    { opacity: 0 },
                    { opacity: 1, ease: "power2.inOut" }
                )
                .to(
                    blockRef1.current,
                    { opacity: 0, ease: "power2.inOut" },
                    "+=" + Math.random()
                );
            if (blockRef2.current) {
                blocktl2.current = gsap
                    .timeline({ repeat: -1, delay: Math.random() * 2 })
                    .fromTo(
                        blockRef2.current,
                        { opacity: 0 },
                        { opacity: 1, ease: "power2.inOut" }
                    )
                    .to(
                        blockRef2.current,
                        { opacity: 0, ease: "power2.inOut" },
                        "+=" + Math.random()
                    );
            }
        });

        if(inView) {
            blocktl1.current.play();
            if(blocktl2.current) 
                blocktl2.current.play();
        } else {
            blocktl1.current.pause(0.1);
            if (blocktl2.current)
                blocktl2.current.pause(0.1);
        }

        return () => ctx.revert();
    }, [inView]);

    return (
        <Group x={x} y={y}>
            {colors.map((c, i) => {
                const pos = i % 2 ? -dim * scales[i] : 0;

                return (
                    <Group
                        y={pos}
                        x={pos * (swapPos ? -1 : 1)}
                        key={i}
                        ref={refs[i]}
                    >
                        <Rect
                            width={dim * scales[i]}
                            height={dim * scales[i]}
                            fill={c}
                        />
                    </Group>
                );
            })}
        </Group>
    );
};

export default DataBitBlocksGroup;
