import React, {useRef, useLayoutEffect} from 'react';
import { useInView } from 'react-intersection-observer';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
// import image1 from './i/Homepage-Hero.png';
import SpadeDetails from './SpadeDetails/SpadeDetails';
import HowSpadeWorks from './HowSpadeWorks/HowSpadeWorks';
import PurposeBuiltSolutions from './PurposeBuiltSolutions/PurposeBuiltSolutions';
import DeveloperFocused from './DeveloperFocused/DeveloperFocused';
import { Helmet } from 'react-helmet-async';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';
import HeroAnimation from 'components/HeroAnimation/HeroAnimation';
import gsap from 'gsap';
import LogosContent from "../About/LogosContent/LogosContent";
import OurInvestors from "../About/OurInvestors/OurInvestors";

const Home = (props) => {

    const [isToggled, toggle] = useToggle(false);
    const {isMobile} = props;

    
    const block1Ref = useRef();
    const block2Ref = useRef();
    const fadeInTl = useRef();
    const [inViewRef, inView] = useInView({ triggerOnce:true });

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            fadeInTl.current = gsap
                .timeline({ paused: true })
                .from([block1Ref.current,block2Ref.current], {
                    y: 30,
                    opacity: 0,
                    ease: "power3.out",
                    stagger: 1.0,
                    duration:2.0,
                    delay:0.5
                });
        });

        if (inView) {
            fadeInTl.current.play();
        }

        return () => ctx.revert();
    },[inView]);

    return (
        <Wrapper addClass="home" {...props}>
            
            <Helmet>
                <title>Spade | Transaction enrichment API</title>
                <meta name="description" content="Discover a transaction enrichment solution powered by ground-truth data with real-time response rates, near-universal coverage, and granular categorizations." />
            </Helmet>

            <div className="blockwrap hero">

                <div className="container" ref={inViewRef}>
                    <div className="content">


                        <div className="block-1" ref={block1Ref}>
                            {/*<h1>Enriched<br /> transaction<br /> data you<br className='mobile' /> can<br className='desktop' /> build<br className='mobile' /> on</h1>*/}
                            <h1>Real-time merchant intelligence for the card ecosystem</h1>
                            <p>Spade’s API provides granular merchant, category, and geolocation details, using ground truth—not guesswork—to deliver exceptionally accurate results in real time.</p>
                            <button onClick={toggle} className="btn-general blue">Learn More</button>
                        </div>
                        <div className='block-2' ref={block2Ref}>
                            {/* {isMobile ? (
                                <img src={image1} alt="placeholder imager" />
                            ) : (   */}
                                {/* <Suspense> */}
                                    <HeroAnimation maxHeight={420} isMobile={isMobile} parentRef={block1Ref} />
                                {/* </Suspense> */}
                            {/* )} */}
                        </div>
                        
                    </div>
                </div>

            </div>
            <LogosContent />
            <HowSpadeWorks isMobile={props.isMobile} />
            <SpadeDetails />

            <PurposeBuiltSolutions isMobile={props.isMobile} />
            <DeveloperFocused />

            <OurInvestors />

            {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
                <FormApiAccess />
            </Modal>}

        </Wrapper>
        
        
    )
}
export default Home;
