import ArrowAngled from 'components/Svg/ArrowAngled/ArrowAngled';
import React from 'react';
import './style.scss';
 
const OurInvestors = (props) => {

    return (
        <div className='blockwrap our-investors'>
            
            <div className='container'>
                <div className='content'>

                    <div className='border-wrap'>
                        <h2>Our investors</h2>

                        <div className='options-wrap'>
                            <a href="https://flourishventures.com/" target="_blank"  rel="noopener noreferrer">Flourish <ArrowAngled /></a>
                            <a href="https://a16z.com/" target="_blank"  rel="noopener noreferrer">a16z <ArrowAngled /></a>
                            <a href="https://www.gradient.com/" target="_blank"  rel="noopener noreferrer">Gradient <ArrowAngled /></a>
                            <a href="https://www.ycombinator.com/" target="_blank"  rel="noopener noreferrer">Y Combinator <ArrowAngled /></a>
                            <a href="https://dashfund.co/" target="_blank"  rel="noopener noreferrer">Dash Fund <ArrowAngled /></a>
                        </div>

                    </div>

                </div>
            </div>
            
          
              
        </div>

    )
}
export default OurInvestors;