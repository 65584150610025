import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
import SolutionsInfoBlocks from './SolutionsInfoBlocks/SolutionsInfoBlocks';
import GetStartedBlock from './GetStartedBlock/GetStartedBlock';
import { Helmet } from 'react-helmet-async';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';

const Solutions = (props) => {

    const [isToggled, toggle] = useToggle();

    return (
        <Wrapper addClass="solutions" {...props}>

            <Helmet>
                <title>Spade | Transaction Data Solutions</title>
                <meta name="description" content="Learn how Spade’s transaction data solutions work for lenders, neobanks, card issuers, and other fintech innovators." />
            </Helmet>

             <div className="blockwrap hero">
                <div className="container">
                    <div className="content">

                        <div className="block-1">
                            <h1>Unlock the<br className="mobile" /> future<br className="desktop" /> of<br className="mobile" /> finance</h1>
                            <p>Purpose-built transaction data solutions<br />for the next generation of fintech.</p>
                            <button onClick={toggle} className="btn-general api-access">Learn More</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            <SolutionsInfoBlocks />
            <GetStartedBlock />

            {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
                <FormApiAccess />
            </Modal>}

        </Wrapper>
    )
}
export default Solutions;