import React from 'react';
import './style.scss';

const Backdrop = (props) => {
//console.log('backdrop ', props);
    return (
        <div className="backdrop" onClick={props.hideBackdrop}>
              <span>
                <span className='first'></span>
                <span className='second'></span>
              </span>
        </div>

    )
}
export default Backdrop;