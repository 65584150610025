import React from 'react';
import './style.scss';
import SpadeLogo from 'components/Svg/SpadeLogo/SpadeLogo';
import { Link } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
// import Modal from 'components/UI/Modal/Modal';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import NavDots from 'components/Svg/NavDots/NavDots';

const MobileHeader = (props) => {
   
     //const [showModal, setShowModal] = useState(false);
    const [isToggled, toggle] = useToggle(false);

    return (
        <>
            <div className="blockwrap">
                <div className="container">
                    <header className="mobile-header">
                    
                            <Link to="/"><SpadeLogo {...props} className={isToggled ? 'open' : ''} /></Link>
                        
                    </header>
                </div>
                {/* <button className={`button-menu ${props.addClass} ${isToggled ? 'open' : ''}`} onClick={toggle}><span></span></button>   */}
                <button className={`button-menu ${props.addClass} ${isToggled ? 'open' : ''}`} onClick={toggle}><NavDots {...props} isToggled={isToggled} /></button>  
            </div>


            {/* <button className={`button-menu ${props.addClass} ${isToggled ? 'open' : ''}`} onClick={toggle}><span></span></button>     */}
            <MobileMenu openMenu={isToggled} {...props} />
        </>
       
    )
}
export default MobileHeader;