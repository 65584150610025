import React from 'react';
import './style.scss';
import SpadeLogo from '../../Svg/SpadeLogo/SpadeLogo';
import { Link } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';

const DesktopHeader = (props) => {
  const [isToggled, toggle] = useToggle(false);

  return (
    <>
      <div className="blockwrap">
        <div className="container">
          <header className="header">
            <Link to="/"><SpadeLogo /></Link>
            <nav>
              <ul>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/solutions">Solutions</Link></li>
                <li><a href="https://docs.spade.com" className='main'>Docs</a></li>
                <li><a href="https://blog.spade.com" className='main'>Blog</a></li>
              </ul>
            </nav>
            <button onClick={toggle} className="access">Learn More</button>
          </header>
        </div>
      </div>

      {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
        <FormApiAccess />
      </Modal>}

    </>
  )
}
export default DesktopHeader;