import React from 'react';
import './style.scss';

import corpay from './i/corpay.svg';
import unit from './i/unit.png';
import mercury from './i/mercury.svg';
import relay from './i/relay.svg';
import sardine from './i/sardine.svg';
import atelio from './i/atelio.svg';
import coast from './i/coast.png';
import stripe from './i/stripe.svg';
import fuelman from './i/fuelman.png';



const LogosContent = (props) => {

    return (
      <div className='blockwrap logos-content'>
        <div className='container'>
          <span className='trusted-by'>Trusted by customers processing $100B+ in payments each year</span>
          <div className='content'>

            <div className='logos-wrap'>
              <div className='logo_holder'>

                <a href="https://stripe.com/">
                  <img src={stripe} alt='stripe logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://www.corpay.com/">
                  <img src={corpay} alt='corpay logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://www.unit.co/">
                  <img src={unit} alt='unit logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://mercury.com/">
                  <img src={mercury} alt='mercury logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://relayfi.com/">
                  <img src={relay} alt='relay logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://www.sardine.ai/">
                  <img src={sardine} alt='sardine logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://atelio.com/">
                  <img src={atelio} alt='atelio logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://coastpay.com/">
                  <img src={coast} alt='coast logo' className='logo'/>
                </a>
              </div>
              <div className='logo_holder'>
                <a href="https://www.fuelman.com/">
                  <img src={fuelman} alt='fuelman logo' className='logo'/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
}
export default LogosContent;
