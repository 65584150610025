import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import TriangleIcon from 'components/Svg/TriangleIcon/TriangleIcon';
import useToggle from 'hooks/useToggle';
import Modal from 'components/UI/Modal/Modal';
import FormApiAccess from 'components/FormApiAccess/FormApiAccess';
import SOC from 'assets/images/SOC-logo-blue.png';

const Footer = (props) => {
  const year = new Date().getFullYear();
  const [isToggled, toggle] = useToggle(false);
  return (
    <footer>
      <div className="blockwrap footer row-1">

        <div className='container'>
          <div className='content'>

            <div className="option api-access">
              <button onClick={toggle} className='footer-heading btn-api-access'>Learn More</button>
            </div>
            <div className='option connect'>
              <h5 className='footer-heading'>Connect</h5>
              <a href='https://boards.greenhouse.io/spade' className='footer-link' target="_blank" rel="noreferrer">Careers</a>
              <a href="mailto:hello@spade.com" title="contact link" className='footer-link'>hello@spade.com</a>
            </div>
            <div className='option social'>
              <h5 className='footer-heading'>Follow Us</h5>
              <a href="https://blog.spade.com" title="contact link" className='footer-link' target='_blank'  rel="noreferrer">Blog</a>
              <a href="https://www.linkedin.com/company/spadedata/" title="contact link" className='footer-link' target='_blank' rel="noreferrer" >Linkedin</a>
              <a href="https://twitter.com/Spadedata" title="contact link" className='footer-link' target='_blank'  rel="noreferrer">Twitter</a>
            </div>
            <div className='option security-privacy'>
              <h5 className='footer-heading'>Legal & Security</h5>
              <Link to="/terms" className='footer-link'>Terms Of Service</Link>
              <Link to="/privacy" className='footer-link'>Privacy Policy</Link>
              <Link to="/security" className='footer-link'>Security</Link>
              <Link to="/msa" className='footer-link'>MSA</Link>
              <Link to="/sla" className='footer-link'>SLA</Link>
            </div>
          </div>
        </div>

      </div>

      <div className='blockwrap footer row-2'>
        <div className='container'>
          <div className="spade-logo">
            <Link to='/'><TriangleIcon /></Link>
            <p className='copyright'>&copy; Spade Data, Inc.</p>
          </div>
          <img src={SOC} alt='soc logo' className="soc-logo"/>
        </div>
      </div>

      {isToggled && <Modal showBackdrop={true} hideBackdrop={toggle}>
        <FormApiAccess />
      </Modal>}

    </footer>

  )
}
export default Footer;
